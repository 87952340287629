// utils/searchCars.ts
import axios from 'axios';
import { apiAxios, authApiAxiosWithRefresh } from './customAxios';

/**
 * Sends a POST request with the search term.
 * @param searchTerm The term to search for
 * @returns The response from the server
 */

interface SearchParams {
    page: number;
    itemsPerPage: number;
    [key: string]: any;
}

let requestCounter = 0;
export const searchCars = async (params: SearchParams) => {
    const currentRequest = ++requestCounter;
    
    const { searchTerm, page, itemsPerPage, ...otherParams } = params;

    const data = {
        query: searchTerm,
        page: page,
        items_per_page: itemsPerPage,
        ...otherParams
    }
    
    try {
        const response = await (
            await authApiAxiosWithRefresh()
          ).post('/search', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        
        if (currentRequest === requestCounter) {
            return response.data;
        }
        return null;
    } catch (error: unknown) {
        console.error('Error posting data:', error);
    }
};