// src/components/TopBar/TopBar.tsx
import React, { useState, useEffect } from "react";
import "./TopBar.scss";
import logo from "../../assets/pacerscout.png";
import mobileLogo from "../../assets/icon.png"; // Path to the new mobile logo
import { MailOutlined, UserOutlined, HomeOutlined } from "@ant-design/icons"; // Import Ant Design email icon
import { Button, Modal } from "antd";
import { signout } from "../../utils/Signout";
import { useNavigate, Link } from "react-router-dom";
// import { Link } from 'react-router-dom';


interface TopBarProps {
  title: string;
  className?: string;
  children?: React.ReactNode; // Add children prop
}

const TopBar: React.FC<TopBarProps> = ({ title, className }) => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth); // State for storing window width
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    window.open("https://app.pacer.co/", "_blank");
  };

  // Hook to handle window resize event
  useEffect(() => {
    // Function to update the window width state
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array to ensure this runs only once when component mounts

  const handleLogout = async () => {
    await signout(); // Call the logout utility function
    navigate("/signin"); // Redirect to login page after logout
  };

  const handleAccountClick = () => {
    navigate("/account"); // Navigate to the account page
  };

  return (
    <div className="top-bar">
      <div className="top-bar-left">
        <Link to="/landing">  {/* Route to home on click */}
          <img
            src={window.innerWidth <= 768 ? mobileLogo : logo} // Conditionally render mobile or full logo
            alt="Pacer Logo"
            className="logo"
          />
        </Link>
      </div>
      <h1 className={`top-bar-title  ${className}`}>{title}</h1>
      {/* <div className="top-bar-right">
        {user && <a onClick={handleLogout}>Logout</a>}
      </div> */}
      <div className="top-bar-right-icon">
        <Button className="home-icon"
          icon={<HomeOutlined style={{ fontSize: "24px" }} />}
          onClick={showModal}
          style={{ cursor: "pointer" }}
        />
        <UserOutlined
          onClick={handleAccountClick}
          style={{ fontSize: "24px", cursor: "pointer" }}
        />
      </div>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        cancelText="No"
        closable={false}
      >
        <h3>Return to Pacer main site?</h3>
      </Modal>
    </div>
  );
};

export default TopBar;

// {
//   window.innerWidth <= 768 ? (
//     // Show email icon on mobile
//     <a href="mailto:info@pacer.co">
//       <MailOutlined style={{ color: "#ffffff", fontSize: "1.5rem" }} />
//     </a>
//   ) : (
//     // Show full email on desktop
//     <a href="mailto:info@pacer.co">info@pacer.co</a>
//   );
// }
