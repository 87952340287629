import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./views/Home/Home";
import Signin from "./views/SignIn/Signin";
import Signup from "./views/SignUp/Signup";
import { UserProvider } from "./context/UserContext";
import Account from "./views/Account/Account";
import { sendPageView } from "./utils/analytics";
import { auth } from "./utils/firebase";
import CarDetails from "./components/CarDetails/CarDetails";


import Landing from "./views/Landing/Landing";

function App() {
  const [userVar, setUser] = useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation(); // To get the current URL's search params

  useEffect(() => {
    // Send page view when the route changes
    sendPageView("/scout");
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);

      // If not authenticated and not already on the signin page, redirect
      if (!user) {
        const currentPath = location.pathname + location.search;

        // Prevent recursive redirects by checking if we're already on /signin
        if (!currentPath.startsWith("/signin")) {
          navigate(`/signin?redirectTo=${encodeURIComponent(currentPath)}`);
        }
      }
    });

    return unsubscribe;
  }, [navigate, location]);

  return (
    <UserProvider>
      <Routes>
        <Route path="/" element={userVar ? <Home /> : <Signin />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/landing" element={userVar ? <Landing /> : <Signin />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/account"
          element={userVar ? <Account email={userVar?.email} /> : <Signin />}
        />
        <Route path="/car/:id" element={<CarDetails />} />
        </Routes>
    </UserProvider>
  );
}

export default App;
