import React, {useState} from 'react';
import { Table, ConfigProvider, theme, Spin } from 'antd';
import { FilePdfOutlined,LoadingOutlined } from '@ant-design/icons';
import type { SortOrder } from 'antd/es/table/interface';
import type { AlignType } from 'rc-table/lib/interface';
import { downloadPPIReport } from '../../utils/getPPI';

import './PPITable.scss';

export interface PPIReport {
  id: string;
  created_at: string;
  name: string;
}

interface PPITableProps {
  reports: PPIReport[];
}

const PPITable: React.FC<PPITableProps> = ({ reports }) => {
  const [isDownloading, setIsDownloading] = useState<{[key: string]: boolean}>({});


  const columns = [
    {
        title: 'Report Date',
        dataIndex: 'created_at',
        key: 'created_at',
        defaultSortOrder: 'descend' as SortOrder,
        sorter: (a: PPIReport, b: PPIReport) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),

      },
    {
      title: 'Vehicle',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Download',
      key: 'download',
      align: 'center' as AlignType,
      render: (_: any, record: PPIReport) => (
        isDownloading[record.id] ? (
          <Spin indicator={<LoadingOutlined style={{ fontSize: '20px', color: 'red' }} spin />} />
        ) : (
          <FilePdfOutlined 
            style={{ fontSize: '20px', cursor: 'pointer', color: 'red'}}
            onClick={async () => {
              setIsDownloading(prev => ({ ...prev, [record.id]: true }));
              try {
                const blob = await downloadPPIReport(record.id);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = `PPI_Report_${record.name}.pdf`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
              } catch (error) {
                console.error('Failed to download PDF:', error);
              } finally {
                setIsDownloading(prev => ({ ...prev, [record.id]: false }));
              }
            }}
          />
        )
      ),
  }
  ];

  return (
    <div className="ppi-reports-section" style={{ maxWidth: '800px', margin: '48px auto 0' }}>
        

      <h2>Past PPI Reports</h2>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm
        }}
      >
      <Table 
        dataSource={reports} 
        columns={columns} 
        rowKey="id"
        pagination={{ pageSize: 10 }}
        size="small"
        showSorterTooltip={{ target: 'sorter-icon' }}
        style={{ 
            border: '1px solid #303030',
            borderRadius: '8px',
            overflow: 'hidden'
          }}
      />
      </ConfigProvider>
    </div>
  );
};

export default PPITable;
