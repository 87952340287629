import { Button, Modal, Input, Form, Flex, Spin } from 'antd';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';
import { useState } from 'react';
import { authPpiApiAxiosWithRefresh } from '../../utils/customAxios';
import { AxiosProgressEvent } from 'axios';

interface RepairAnalysisProps {
  car: any;
}

const RepairAnalysis: React.FC<RepairAnalysisProps> = ({ car }) => {
  // console.log(car);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<'idle' | 'success' | 'error' | 'loading'>('idle');
  const [progress, setProgress] = useState<string>('');


  const showModal = () => {
    setIsModalVisible(true);
    setStatus('idle'); // Reset status on opening modal
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleSubmit = async (values: { email: string }) => {
    setLoading(true);
    setStatus('loading');

    try {
        const data = {
            email: values.email,
            link: car.link,
        };

        const axiosInstance = await authPpiApiAxiosWithRefresh();
        
        const response = await axiosInstance.post('/CABreconditioning', data, {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'text/event-stream'
          },
          responseType: 'stream',
          onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
              const message = progressEvent.event?.target?.response;
              const cleanMessage = message?.split('data:').pop()?.trim();
              const messageData = JSON.parse(cleanMessage || '');
              if (messageData.status === 200){
                setStatus('success');
              } else if (messageData.status === 400){
                setStatus('error');
              }
              setProgress(messageData.message);
          }
      });

        form.resetFields();
    } catch (error) {
        console.error('Error submitting repair request:', error);
        setStatus('error');
    } finally {
        setLoading(false);
    }
};


  
  return (
    <>
      <Button onClick={showModal}>Repair Analysis</Button>
      <Modal title={car.name} open={isModalVisible} onCancel={handleCancel} footer={null} width="fit-content">
        <Form form={form} onFinish={handleSubmit} style={{ display: 'flex', flexDirection: 'column' }}>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'Please enter a valid email!' },
            ]}
            style={{ width: '90%', margin: '0 0 1rem 1rem' }}
          >
            <Input placeholder="Enter your email address" />
          </Form.Item>

          <Form.Item className="form-button" style={{ width: '90%', margin: '0 0 1rem 1rem' }}>
            <Button type="primary" htmlType="submit" disabled={loading}>
              Request AI Repair Analysis
            </Button>
          </Form.Item>
          {
            status !== 'idle' ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '1rem 0 3rem 0' }}>
                <div style={{ marginBottom: '1rem', textAlign: 'center' }}>
                  {progress}
                </div>
                {loading && (
                  <Spin />
                )}
                {status === 'success' && <CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: 24 }} />}
                {status === 'error' && <span><CloseCircleTwoTone twoToneColor="#ff4d4f" style={{ fontSize: 24 }} /></span>}
              </div>
            ) : null
          }
        </Form>
      </Modal>
    </>
  );
};

export default RepairAnalysis;
