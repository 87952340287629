import { apiAxios, authApiAxiosWithRefresh } from './customAxios';

interface PPIReport {
    id: string;
    created_at: string;
    name: string;
  }

export const getPPIreports = async (): Promise<PPIReport[]> => {
    try {
        const response = await (await authApiAxiosWithRefresh()).get('/get_ppi_reports');
        return response.data;
    } catch (error) {
        console.error('Error fetching PPI reports:', error);
        return [];
    }
};

export const downloadPPIReport = async (reportId: string): Promise<Blob> => {
    try {
        const response = await (await authApiAxiosWithRefresh()).get(`/download_ppi_report/${reportId}`, {
            responseType: 'blob'
        });
        return response.data;
    } catch (error) {
        console.error('Error downloading PPI report:', error);
        throw error;
    }
};