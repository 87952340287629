import React, {useState, useEffect} from 'react';
import { Collapse, Button } from 'antd';
import { FilterYear } from './filterYear/FilterYear';
import { FilterMakes } from './filterMakes/FilterMakes';
import { FilterLocation } from './filterLocation/FilterLocation';
import { FilterWebsite } from './filterWebsite/FilterWebsite';
import { FilterReserve } from './filterReserve/FilterReserve';
import { FilterModels } from './filterModels/FilterModels';
import { FilterSellerType} from './filterSellerType/FilterSellerType'
import { FilterTitleStatus } from './filterTitleStatus/FilterTitleStatus';
import { FilterValues, defaultFilterValues } from '../../types/home';

import { getMakes, getModels, getWebsites } from '../../utils/getFilters';
import { useDebouncedCallback } from '../../utils/debounce';
import './FilterOptions.scss';

const { Panel } = Collapse;

interface FilterOptionsProps {
    filterValues: any;
    setFilterValues: React.Dispatch<React.SetStateAction<any>>;
    onFilterValuesChange: (filterValues: any) => void;
}

const FilterOptions: React.FC<FilterOptionsProps> = ({ filterValues, setFilterValues, onFilterValuesChange }) => {
    const [makes, setMakes] = useState<string[]>([]);
    const [models, setModels] = useState<string[]>([]);
    const [websites, setWebsites] = useState<string[]>([]);

    const handleFilterChange = (updates: { [key: string]: string | string[] }) => {
        setFilterValues((prevValues: FilterValues) => ({ 
            ...prevValues, 
            ...updates 
        }));
    };

    const debouncedFetchMakes = useDebouncedCallback(async () => {
        const data = await getMakes(filterValues);
        setMakes(data);
    }, 500);
    
    const debouncedFetchModels = useDebouncedCallback(async () => {
        if (filterValues.selectedMakes.length > 0) {
            const data = await getModels(filterValues);
            setModels(data);
        } else {
            setModels([]);
        }
    }, 500);
    
    const debouncedFetchSites = useDebouncedCallback(async () => {
        const data = await getWebsites(filterValues);
        setWebsites(data);
    }, 500);

    

    //dynamically update filters with debouncing
    //makes
    useEffect(() => {
        debouncedFetchMakes();
    }, [filterValues.websites, filterValues.reserve, filterValues.sellerType]);

    //models
    useEffect(() => {
        debouncedFetchModels();
    }, [filterValues.selectedMakes, filterValues.websites, filterValues.reserve, filterValues.sellerType]);

    //auction sites
    useEffect(() => {
        debouncedFetchSites();
    }, [filterValues.selectedMakes, filterValues.selectedModels, filterValues.reserve, filterValues.sellerType]);

    useEffect(() => {
        onFilterValuesChange(filterValues);
    }, [filterValues, onFilterValuesChange]);
    
    
    const clearAllFilters = () => {
        setFilterValues(defaultFilterValues);
    };

    return (
        <div className="filter-options">
            <Collapse>
                <Panel header="Filter by Make" key="1">
                    <FilterMakes 
                        filterMakes={makes}
                        selectedMakes={filterValues.selectedMakes}
                        onChange={(id, value) => handleFilterChange({ [id]: value })}/>
                </Panel>
                <Panel header="Filter by Model (Beta)" key="2">
                    {filterValues.selectedMakes.length > 0 ? (
                        <FilterModels
                            filterModels={models}
                            selectedModels={filterValues.selectedModels}
                            onChange={(id, value) => handleFilterChange({ [id]: value })}
                        />
                    ) : (
                        <div style={{ fontStyle: 'italic' }}>Please select a Make first!</div>
                    )}
                </Panel>
                <Panel header="Filter by Year" key="3">
                    <FilterYear 
                        startYear={filterValues.startYear} 
                        endYear={filterValues.endYear} 
                        onChange={(id, value) => handleFilterChange({ [id]: value })}/>
                </Panel>
                <Panel header="Filter by Location" key="4">
                    <FilterLocation
                        location={filterValues.location}
                        distance={filterValues.distance}
                        onChange={handleFilterChange}
                    />
                </Panel>
                <Panel header="Filter by Website" key="5">
                    <FilterWebsite 
                        auction_sites={websites}
                        websites={filterValues.websites}
                        onChange={(id, value) => handleFilterChange({ [id]: value })}
                    />
                </Panel>
                <Panel header="Filter by Reserve Status" key="6">
                    <FilterReserve
                        reserve={filterValues.reserveStatus}
                        onChange={(id, value) => handleFilterChange({ [id]: value })}
                    />
                </Panel>
                <Panel header="Filter by Seller Type" key="7">
                    <FilterSellerType
                        sellerType={filterValues.sellerType}
                        onChange={(id, value) => handleFilterChange({ [id]: value })}
                    />
                </Panel>
                <Panel header="Filter by Title Status (Beta)" key="8">
                    <FilterTitleStatus
                        titleStatus={filterValues.titleStatus}
                        onChange={(id, value) => handleFilterChange({ [id]: value })}
                    />
                </Panel>
            </Collapse>
            <Button type="primary" className="clear-filters" onClick={clearAllFilters}>Clear All Filters</Button>
        </div>
    );
};

export { FilterOptions };