import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Button, Select, Spin, Switch, Checkbox, Col, Row, Radio, Space } from "antd";
import { MenuOutlined, FilterOutlined, SortAscendingOutlined} from "@ant-design/icons";
import { searchCars } from '../../utils/searchCars';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { FilterOptions } from '../../components/FilterOptions/FilterOptions';
import { CarTable } from '../../components/CarTable/CarTable';
import CarCards from '../../components/CarCards/CarCards';
import TopBar from '../../components/TopBar/TopBar';
import './Home.scss';
import { Car, FilterValues, defaultFilterValues } from '../../types/home';
import { useNavigate } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { CarProvider } from '../../components/CarContext/CarContext';
import { formatCarData } from '../../utils/formatCarData';
import { useDebouncedCallback } from '../../utils/debounce';
const { Option } = Select;

const Home: React.FC = () => {
    const [userVar, setUser] = useState<any>(null);
    const navigate = useNavigate();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [isSortDrawerVisible, setIsSortDrawerVisible] = useState(false);
    const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCars, setFilteredCars] = useState<Car[]>([]);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [includeBuyersFee, setIncludeBuyersFee] = useState(false);
    const [page, setPage] = useState(1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [cars, setCars] = useState([]);
    const [filterValues, setFilterValues] = useState<FilterValues>(defaultFilterValues);
    const [loading, setLoading] = useState(false);
    // used to calculate the number of pages to show in the table and cards
    const [carsLength, setCarsLength] = useState(0);
    // sort field and order state
    const [sortField, setSortField] = useState('');
    const [sortOrder, setSortOrder] = useState('');
    const [sortListValue, setSortListValue] = useState('timeLeft-ascend');
    const [starToggle, setStarToggle] = useState('all'); // 'all' or 'starred'
    const [pastToggle, setPastToggle] = useState('current');
    const [starChanged, setStarChanged] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);




    const handleItemsPerPageChange = (value: number) => {
        setItemsPerPage(value);
    };

    const handleIncludeBuyersFeeChange = (value: boolean) => {
        setIncludeBuyersFee(value);
    }
    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        setIncludeBuyersFee(e.target.checked);
    };
    const handleSearchTermChange = (term: string) => {
        setSearchTerm(term);
    };

    const handleFilterValuesChange = (newFilterValues: any) => {
        setFilterValues(newFilterValues);
    };

    const showDrawer = () => {
        setIsDrawerVisible(true);
    };
    const showSortDrawer = () => {
        setIsSortDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };
    const closeSortDrawer = () => {
        setIsSortDrawerVisible(false);
    };
    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };

    const handleSortChange = (field: string, order: string) => {
        // console.log('Sort field:', field, 'Sort order:', order);
        setSortField(field);
        setSortOrder(order);
    };

    const onSortChange = (e: any) => {
        // console.log('radio checked', e.target.value);
        setSortListValue(e.target.value);
        const [field, order] = e.target.value.split('-');
        setSortField(field);
        setSortOrder(order);
    }

    // Helper function to format time left
    const formatTimeLeft = (endTime: number): string => {
        const timeDiff = endTime * 1000 - Date.now();
        if (timeDiff <= 0) return "Ending Soon";

        const hours = Math.floor(
            (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
        return `${hours} hours, ${minutes} minutes, ${seconds} seconds`;
    };

    const [isStarredView, setIsStarredView] = useState(false);

    const handleStarToggleChange = (option: React.SetStateAction<string>) => {
        setStarToggle(option);
    };

    const handlePastToggleChange = (option: React.SetStateAction<string>) => {
        setPastToggle(option);
    }

    const debouncedSearch = useDebouncedCallback(async (params) => {
        setLoading(true);

        // generate unique request ID
        

        try {
            const data = await searchCars(params);
            
            if (data) {
                setCars(data?.cars || []);
                setCarsLength(data?.carsLength || 0);
                setLoading(false);
            } 
        } catch (error) {
            setLoading(false);
        }
    }, 500);
    // Fetch data from the server when the page, itemsPerPage, searchTerm, or filter info changes
    useEffect(() => {
        if (isInitialized) {
            const searchParams = {
                page,
                itemsPerPage,
                searchTerm,
                sortField,
                sortOrder,
                includeBuyersFee,
                starredOnly: starToggle === "starred",
                current: pastToggle === "current",
                ...filterValues,
            };
            debouncedSearch(searchParams);
        }
        setStarChanged(false);
    }, [page, itemsPerPage, includeBuyersFee, searchTerm, filterValues, sortOrder, starToggle, pastToggle, starChanged, isInitialized]);

    // format car data to match table output and sort by time left
    useEffect(() => {
        if (cars) {
            const formattedData = formatCarData(cars);
            setFilteredCars(formattedData);
        }
    }, [cars]);

    // save filters state for when we come back to the main page
    const saveTableState = () => {
        const currentState = {
            page,
            itemsPerPage,
            searchTerm,
            sortField,
            sortOrder,
            includeBuyersFee,
            starToggle,
            pastToggle,
            filterValues
        };
        localStorage.setItem('tableState', JSON.stringify(currentState));
    };
    
    // use filters state if available
    useEffect(() => {
        const savedState = localStorage.getItem('tableState');
        if (savedState) {
            const state = JSON.parse(savedState);

            setPage(state.page);
            setItemsPerPage(state.itemsPerPage);
            setSearchTerm(state.searchTerm);
            setSortField(state.sortField);
            setSortOrder(state.sortOrder);
            setIncludeBuyersFee(state.includeBuyersFee);
            setStarToggle(state.starToggle);
            setPastToggle(state.pastToggle);
            setFilterValues(state.filterValues);

        }
        setIsInitialized(true);

    }, []);
    
    // Effect to listen to window resize events and update `isMobile` state
    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <CarProvider cars={cars}>  
            <div className="home-container">
                <TopBar title="Live Auctions" />

                <div className="main-content">
                    {/* Hamburger Menu Button for mobile */}
                    {isMobile && (
                        <div className="icon-div">
                            <Button
                                className="hamburger-menu"
                                icon={<FilterOutlined style={{ fontSize: '25px' }} />}
                                onClick={showDrawer}
                            />
                            <Button
                                className="hamburger-menu"
                                icon={<SortAscendingOutlined  style={{ fontSize: '25px' }} />}
                                onClick={showSortDrawer}
                            />
                        </div>

                    )}

                    {/* Filter Sidebar - Drawer for mobile, inline for desktop */}
                    {isMobile ? (
                        <div>

                            <Drawer
                                title="Filters"
                                placement="left"
                                onClose={closeDrawer}
                                open={isDrawerVisible}
                                width={250}
                            >
                            <SearchBar onSearchTermChange={handleSearchTermChange} value={searchTerm} />
                            <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "center" }}>
                                    <Button.Group style={{ width: "100%" }}>
                                        <Button
                                            type={starToggle === 'all' ? 'primary' : 'default'}
                                            onClick={() => handleStarToggleChange('all')}
                                            style={{ width: "50%" }}
                                        >
                                            All
                                        </Button>
                                        <Button
                                            type={starToggle === 'starred' ? 'primary' : 'default'}
                                            onClick={() => handleStarToggleChange('starred')}
                                            style={{ width: "50%" }}
                                        >
                                            Starred
                                        </Button>
                                    </Button.Group>
                                </div>
                                <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "center" }}>
                                    <Button.Group style={{ width: "100%" }}>
                                        <Button
                                            type={pastToggle === 'current' ? 'primary' : 'default'}
                                            onClick={() => handlePastToggleChange('current')}
                                            style={{ width: "50%" }}
                                        >
                                            Current
                                        </Button>
                                        <Button
                                            type={pastToggle === 'past' ? 'primary' : 'default'}
                                            onClick={() => handlePastToggleChange('past')}
                                            style={{ width: "50%" }}
                                        >
                                            Past
                                        </Button>
                                    </Button.Group>
                                </div>
                                    <Checkbox
                                        checked={includeBuyersFee}
                                        onChange={handleCheckboxChange}
                                        style={{ marginBottom: "1rem" }}
                                    >
                                        Include Buyer's Fee
                                    </Checkbox>
                                <FilterOptions
                                    filterValues={filterValues}
                                    setFilterValues={setFilterValues}
                                    onFilterValuesChange={handleFilterValuesChange}
                                />
                                <div className="items-per-page">
                                    <span>Items per page:</span>
                                    <Select value={itemsPerPage} onChange={handleItemsPerPageChange} style={{ width: '100%' }}>
                                        <Option value={10}>10</Option>
                                        <Option value={20}>20</Option>
                                        <Option value={50}>50</Option>
                                    </Select>
                                </div>
                                <Button type="primary" onClick={closeDrawer} style={{ marginTop: '1rem', width: '100%' }}>
                                    Apply Filters
                                </Button>
                            </Drawer>
                            <Drawer
                                title="Sort"
                                placement="right"
                                onClose={closeSortDrawer}
                                open={isSortDrawerVisible}
                                width={250}
                            >
                                <Radio.Group onChange={onSortChange} value={sortListValue}>
                                    <Space direction="vertical">
                                        <Radio value="name-ascend">Name (A to Z)</Radio>
                                        <Radio value="name-descend">Name (Z to A)</Radio>
                                        <Radio value="currentBid-ascend">Bid (High to Low)</Radio>
                                        <Radio value="currentBid-descend">Bid (Low to High)</Radio>
                                        <Radio value="approxMargin-descend">Margin (High to Low)</Radio>
                                        <Radio value="approxMargin-ascend">Margin (Low to High)</Radio>
                                        <Radio value="timeLeft-ascend">Time Left (High to Low)</Radio>
                                        <Radio value="timeLeft-descend">Time Left (Low to High)</Radio>
                                        <Radio value="location-ascend">Location (A to Z)</Radio>
                                        <Radio value="location-descend">Location (Z to A)</Radio>
                                        <Radio value="website-ascend">Website (A to Z)</Radio>
                                        <Radio value="website-descend">Website (Z to A)</Radio>
                                    </Space>
                                </Radio.Group>
                            </Drawer>
                        </div>
                    ) : (
                        <div className={`filter-sidebar ${isFilterCollapsed ? 'collapsed' : ''}`}>
                            <SearchBar onSearchTermChange={handleSearchTermChange} value={searchTerm} />
                            <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "center" }}>
                                <Button.Group style={{ width: "100%" }}>
                                    <Button
                                        type={starToggle === 'all' ? 'primary' : 'default'}
                                        onClick={() => handleStarToggleChange('all')}
                                        style={{ width: "50%" }}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        type={starToggle === 'starred' ? 'primary' : 'default'}
                                        onClick={() => handleStarToggleChange('starred')}
                                        style={{ width: "50%" }}
                                    >
                                        Starred
                                    </Button>
                                </Button.Group>
                            </div>
                            <div style={{ marginBottom: "1rem", display: "flex", justifyContent: "center" }}>
                                <Button.Group style={{ width: "100%" }}>
                                    <Button
                                        type={pastToggle === 'current' ? 'primary' : 'default'}
                                        onClick={() => handlePastToggleChange('current')}
                                        style={{ width: "50%" }}
                                    >
                                        Current
                                    </Button>
                                    <Button
                                        type={pastToggle === 'past' ? 'primary' : 'default'}
                                        onClick={() => handlePastToggleChange('past')}
                                        style={{ width: "50%" }}
                                    >
                                        Past
                                    </Button>
                                </Button.Group>
                            </div>
                            <Checkbox
                                checked={includeBuyersFee}
                                onChange={handleCheckboxChange}
                                style={{ marginBottom: "1rem" }}
                            >
                                Include Buyer's Fee
                            </Checkbox>
                            <FilterOptions
                                filterValues={filterValues}
                                setFilterValues={setFilterValues}
                                onFilterValuesChange={handleFilterValuesChange}
                            />
                            <div className="items-per-page">
                                <span>Items per page:</span>
                                <Select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                                    <Option value={10}>10</Option>
                                    <Option value={20}>20</Option>
                                    <Option value={50}>50</Option>
                                </Select>
                            </div>

                            {/* Show Hide Filters button only on desktop */}
                            {/* {!isMobile && (
                            <button 
                                className="collapse-button"
                                onClick={() => setIsFilterCollapsed(!isFilterCollapsed)}
                            >
                                {isFilterCollapsed ? 'Show Filters' : 'Hide Filters'}
                            </button>
                        )} */}
                        </div>
                    )}

                    {/* Main Table Content */}
                    <div className="table-container">
                        <Spin spinning={loading} size="large">
                            {isMobile ? (
                                <CarCards
                                    page={page}
                                    itemsPerPage={itemsPerPage}
                                    dataSource={filteredCars}
                                    carsLength={carsLength}
                                    onPageChange={handlePageChange} 
                                    setDataSource={setFilteredCars}
                                    starToggle = {starToggle}
                                    setStarChanged={setStarChanged}
                                    onNavigateToDetails={saveTableState}
                                    pages={true} />
                            ) : (
                                <CarTable
                                    page={page}
                                    itemsPerPage={itemsPerPage}
                                    dataSource={filteredCars}
                                    carsLength={carsLength}
                                    onPageChange={handlePageChange}
                                    onSortChange={handleSortChange}
                                    setDataSource={setFilteredCars}
                                    starToggle = {starToggle}
                                    setStarChanged={setStarChanged} 
                                    onNavigateToDetails={saveTableState}/>
                            )}
                        </Spin>
                    </div>
                </div>
            </div>
        </CarProvider>

    );

};

export default Home;