// Landing.tsx
import React, { useState, useEffect } from "react";
import TopBar from "../../components/TopBar/TopBar";
import { SearchBar } from "../../components/SearchBar/SearchBar";
import { Select, Button, Spin, Alert, Typography, Modal, Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import CarCards from "../../components/CarCards/CarCards";
import "./Landing.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { getPopularCars } from "../../utils/getPopularCars";
import { getMakes, getModels, getWebsites } from "../../utils/getFilters";
import { Car, FilterValues, defaultFilterValues } from "../../types/home";

const { Option } = Select;
const { Title } = Typography;

const Landing: React.FC = () => {
  // 1. isMobile state + effect
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navigate = useNavigate();
  const [page, setPage] = useState<number>(() => Number(1));
  const [sortField, setSortField] = useState<string>(() => "");
  const [sortOrder, setSortOrder] = useState<string>(() => "");
  const [includeBuyersFee, setIncludeBuyersFee] = useState<boolean>(
    () => false
  );

  // State for filters
  const [searchTerm, setSearchTerm] = useState("");
  const [filterValues, setFilterValues] = useState({
    selectedMakes: [] as string[],
    selectedModels: [] as string[],
    websites: [] as string[],
    reserveStatus: [] as string[],
    sellerType: [] as string[],
    pastToggle: [] as string[],
  });

  // Save table state to local storage
  const saveTableState = () => {
    const currentState = {
      page,
      itemsPerPage: 10,
      pastToggle: "current",
      searchTerm,
      sortField,
      sortOrder,
      includeBuyersFee,
      starToggle,
      filterValues,
    };
    localStorage.setItem("tableState", JSON.stringify(currentState));
  };

  // State for car data
  const [cars, setCars] = useState<Array<Car>>([]);
  const [carsLength, setCarsLength] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;
  const [starToggle, setStarToggle] = useState<string>("all");
  const [starChanged, setStarChanged] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // Dynamic options
  const [makes, setMakes] = useState<string[]>([]);
  const [models, setModels] = useState<string[]>([]);
  const [websites, setWebsites] = useState<string[]>([]);

  // Modal state
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  // Drawer state (for mobile filters)
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  // Handlers for Modal
  const showModal = () => setIsModalVisible(true);
  const handleOk = () => setIsModalVisible(false);
  const handleCancel = () => setIsModalVisible(false);

  // Fetch models
  useEffect(() => {
    const fetchModels = async () => {
      if (filterValues.selectedMakes.length > 0) {
        const data = await getModels(filterValues);
        setModels(data);
      } else {
        setModels([]);
      }
    };
    fetchModels();
  }, [
    filterValues.selectedMakes,
    filterValues.websites,
    filterValues.reserveStatus,
    filterValues.sellerType,
  ]);

  // Fetch websites
  useEffect(() => {
    const fetchSites = async () => {
      const data = await getWebsites(filterValues);
      setWebsites(data);
    };
    fetchSites();
  }, [
    filterValues.selectedMakes,
    filterValues.selectedModels,
    filterValues.reserveStatus,
    filterValues.sellerType,
  ]);

  // Fetch makes
  useEffect(() => {
    const fetchSites = async () => {
      const data = await getMakes(filterValues);
      setMakes(data);
    };
    fetchSites();
  }, [
    filterValues.selectedMakes,
    filterValues.selectedModels,
    filterValues.reserveStatus,
    filterValues.sellerType,
  ]);

  // Axios instance (if needed)
  const apiAxios = axios.create({
    baseURL: "http://localhost:5000",
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Fetch popular cars
  const fetchPopularCars = async () => {
    try {
      setLoading(true);
      setError(null);
      const popularCars = await getPopularCars(filterValues);
      setCars(popularCars || []);
      setCarsLength(popularCars ? popularCars.length : 0);
    } catch (err) {
      console.error(err);
      setError("Failed to fetch popular cars.");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch on mount
  useEffect(() => {
    fetchPopularCars();
  }, []);

  // Handlers
  const handleSearchTermChange = (term: string) => {
    setSearchTerm(term);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleFilterChange = (
    filterKey: keyof typeof filterValues,
    values: string[]
  ) => {
    setFilterValues((prevFilters) => ({ ...prevFilters, [filterKey]: values }));
  };

  const onNavigateToDetails = (car: Car) => {
    navigate(`/car-details/${car.key}`);
  };

  const handleSearch = () => {
    const serializedFilters = JSON.stringify(filterValues);
    const queryParams = new URLSearchParams({
      searchTerm: searchTerm || "",
      filterValues: serializedFilters,
      page: "1",
      itemsPerPage: itemsPerPage.toString(),
    });
    saveTableState();
    // Navigate to the homepage with filters if desired
    navigate(`/`);
    // OR: navigate(`/?${queryParams.toString()}`);
  };

  // Drawer togglers
  const openDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  // 2. Render conditionally based on isMobile
  return (
    <>
      {isMobile ? (
        <div className="landing-page-container mobile-version">
          <TopBar title="" />

          <div className="content">
            <div className="learn-more-banner">
              <Typography.Link onClick={showModal}>
                Learn more about Scout (Mobile)
              </Typography.Link>
            </div>

            <div className="search-bar-wrapper">
              <div className="search-bar-container">
                <SearchBar onSearchTermChange={handleSearchTermChange} />
              </div>
              <Button
                type="primary"
                className="search-button"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>

            {/* Button to open filters drawer on mobile */}
            <div style={{ textAlign: "right", margin: "10px 0" }}>
              <Button icon={<MenuOutlined />} onClick={openDrawer}>
                Filters
              </Button>
            </div>

            {/* Drawer containing filters for mobile */}
            <Drawer
              title="Filters"
              placement="right"
              onClose={closeDrawer}
              open={drawerVisible}
            >
              <Select
                mode="multiple"
                placeholder="Make"
                style={{ width: "100%", marginBottom: "8px" }}
                onChange={(values) =>
                  handleFilterChange("selectedMakes", values)
                }
                allowClear
                optionLabelProp="label"
                value={filterValues.selectedMakes}
              >
                {makes.map((make) => (
                  <Option key={make} value={make} label={make}>
                    {make}
                  </Option>
                ))}
              </Select>

              <Select
                mode="multiple"
                placeholder="Model"
                style={{ width: "100%", marginBottom: "8px" }}
                onChange={(values) =>
                  handleFilterChange("selectedModels", values)
                }
                allowClear
                optionLabelProp="label"
                value={filterValues.selectedModels}
                notFoundContent={
                  filterValues.selectedMakes.length === 0
                    ? "Please select a make first"
                    : "No models found"
                }
              >
                {models.map((model) => (
                  <Option key={model} value={model} label={model}>
                    {model}
                  </Option>
                ))}
              </Select>

              <Select
                mode="multiple"
                placeholder="Website"
                style={{ width: "100%", marginBottom: "8px" }}
                onChange={(values) => handleFilterChange("websites", values)}
                allowClear
                optionLabelProp="label"
                value={filterValues.websites}
              >
                {websites.map((website) => (
                  <Option key={website} value={website} label={website}>
                    {website}
                  </Option>
                ))}
              </Select>

              <Select
                mode="multiple"
                placeholder="Reserve Status"
                style={{ width: "100%", marginBottom: "8px" }}
                onChange={(values) =>
                  handleFilterChange("reserveStatus", values)
                }
                allowClear
                optionLabelProp="label"
                value={filterValues.reserveStatus}
              >
                <Option value="Reserve" label="Reserve">
                  Reserve
                </Option>
                <Option value="No Reserve" label="No Reserve">
                  No Reserve
                </Option>
              </Select>

              <Select
                mode="multiple"
                placeholder="Seller Type"
                style={{ width: "100%", marginBottom: "8px" }}
                onChange={(values) => handleFilterChange("sellerType", values)}
                allowClear
                optionLabelProp="label"
                value={filterValues.sellerType}
              >
                <Option value="Dealer" label="Dealer">
                  Dealer
                </Option>
                <Option value="Private" label="Private">
                  Private
                </Option>
              </Select>
            </Drawer>

            {/* Modal */}
            <Modal
              title="About Scout"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button key="close" type="primary" onClick={handleCancel}>
                  Close
                </Button>,
              ]}
            >
              <p>
                Scout is a Pacer product that aggregates auctions from multiple
                sites. (Mobile Explanation)
              </p>
            </Modal>

            {/* Loading and error */}
            {loading && (
              <div className="loading-spinner">
                <Spin tip="Loading popular cars..." />
              </div>
            )}
            {error && (
              <Alert
                message="Error"
                description={error}
                type="error"
                showIcon
                closable
                onClose={() => setError(null)}
                style={{ marginBottom: "20px" }}
              />
            )}

            {/* Car Cards */}
            {!loading && !error && (
              <div className="car-cards-wrapper">
                <Title level={4} className="popular-cards-header">
                  Popular Auctions (Mobile)
                </Title>
                <CarCards
                  page={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataSource={cars}
                  carsLength={carsLength}
                  onPageChange={handlePageChange}
                  setDataSource={setCars}
                  starToggle={starToggle}
                  setStarChanged={setStarChanged}
                  onNavigateToDetails={saveTableState}
                  pages={false}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="landing-page-container">
          <TopBar title="" />
          <div className="content">
            {/* Banner Section */}
            <div className="learn-more-banner">
              <Typography.Link onClick={showModal}>
                Learn more about Scout
              </Typography.Link>
            </div>
            <div className="search-bar-wrapper">
              <div className="search-bar-container">
                <SearchBar onSearchTermChange={handleSearchTermChange} />
              </div>
              <Button
                type="primary"
                className="search-button"
                onClick={handleSearch}
              >
                Search
              </Button>
            </div>

            {/* Modal */}
            <Modal
              title="About Scout"
              visible={isModalVisible}
              onOk={handleOk}
              onCancel={handleCancel}
              footer={[
                <Button key="close" type="primary" onClick={handleCancel}>
                  Close
                </Button>,
              ]}
            >
              <p>
                Scout is a Pacer product that aggregates auctions from multiple
                sites. For each auction, Scout provides key information about
                the car and includes estimates for potential profit or loss
                based on historical data.
              </p>
            </Modal>

            {/* Desktop Filters */}
            <div className="filters-wrapper">
              <Select
                mode="multiple"
                placeholder="Make"
                style={{ width: "200px", marginRight: "10px" }}
                onChange={(values) =>
                  handleFilterChange("selectedMakes", values)
                }
                allowClear
                optionLabelProp="label"
                value={filterValues.selectedMakes}
              >
                {makes.map((make) => (
                  <Option key={make} value={make} label={make}>
                    {make}
                  </Option>
                ))}
              </Select>
              <Select
                mode="multiple"
                placeholder="Model"
                style={{ width: "200px", marginRight: "10px" }}
                onChange={(values) =>
                  handleFilterChange("selectedModels", values)
                }
                allowClear
                optionLabelProp="label"
                value={filterValues.selectedModels}
                notFoundContent={
                  filterValues.selectedMakes.length === 0
                    ? "Please select a make first"
                    : "No models found"
                }
              >
                {models.map((model) => (
                  <Option key={model} value={model} label={model}>
                    {model}
                  </Option>
                ))}
              </Select>

              <Select
                mode="multiple"
                placeholder="Website"
                style={{ width: "200px", marginRight: "10px" }}
                onChange={(values) => handleFilterChange("websites", values)}
                allowClear
                optionLabelProp="label"
                value={filterValues.websites}
              >
                {websites.map((website) => (
                  <Option key={website} value={website} label={website}>
                    {website}
                  </Option>
                ))}
              </Select>
              <Select
                mode="multiple"
                placeholder="Reserve Status"
                style={{ width: "200px", marginRight: "10px" }}
                onChange={(values) =>
                  handleFilterChange("reserveStatus", values)
                }
                allowClear
                optionLabelProp="label"
                value={filterValues.reserveStatus}
              >
                <Option value="Reserve" label="Reserve">
                  Reserve
                </Option>
                <Option value="No Reserve" label="No Reserve">
                  No Reserve
                </Option>
              </Select>
              <Select
                mode="multiple"
                placeholder="Seller Type"
                style={{ width: "200px", marginRight: "10px" }}
                onChange={(values) => handleFilterChange("sellerType", values)}
                allowClear
                optionLabelProp="label"
                value={filterValues.sellerType}
              >
                <Option value="Dealer" label="Dealer">
                  Dealer
                </Option>
                <Option value="Private" label="Private">
                  Private
                </Option>
              </Select>
            </div>

            {loading && (
              <div className="loading-spinner">
                <Spin tip="Loading popular cars..." />
              </div>
            )}
            {error && (
              <Alert
                message="Error"
                description={error}
                type="error"
                showIcon
                closable
                onClose={() => setError(null)}
                style={{ marginBottom: "20px" }}
              />
            )}

            {!loading && !error && (
              <div className="car-cards-wrapper">
                <Title level={2} className="popular-cards-header">
                  Popular Auctions
                </Title>
                <CarCards
                  page={currentPage}
                  itemsPerPage={itemsPerPage}
                  dataSource={cars}
                  carsLength={carsLength}
                  onPageChange={handlePageChange}
                  setDataSource={setCars}
                  starToggle={starToggle}
                  setStarChanged={setStarChanged}
                  onNavigateToDetails={saveTableState}
                  pages={false}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Landing;
